import React, { useEffect, useState } from 'react'
import { handleImageError } from '../utilities/helpers'

const ProgramHero = ({ program, src }) => {
  // eslint-disable-next-line no-unused-vars
  const [urlSlug, setUrlSlug] = useState('')
  
  useEffect(() => {
    const rawTitle = program.title
    const newTitle = rawTitle.replace(/\s+/g, '-').toLowerCase()
    setUrlSlug(newTitle)
  },[program])

  return (
    <div className='hero-container' role='article' aria-label={`Program information for ${program.featuredTitle}`} >
      <div className='hero-img-wrapper' key={program.idNumber} itemid={`#${program.idNumber}`}>
        <img 
          src={src} 
          alt={`Cover for ${program.featuredTitle}`} 
          className='cover hero-img'
          loading='lazy'
          onError={handleImageError}
          itemprop='image'
        />
      </div>
    </div>
  )  
}
export default ProgramHero
