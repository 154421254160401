import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { urlSlugTitle } from '../utilities/helpers'

const GenreDropdownItem = ({genre, hideTooltip}) => {
  const [ urlSlug, setUrlSlug ] = useState()

  useEffect(() => {
    setUrlSlug(urlSlugTitle(genre.name, genre.id))
  }, [genre])

  return (
    <Nav.Item 
      role='menuitem' 
      key={genre.id}
      onClick={hideTooltip}
      >
      <Link to={`/genres/${urlSlug}`} state={{genre}} className='text-nowrap' onClick={hideTooltip}>{genre.name}</Link>
    </Nav.Item>
  )
}
export default GenreDropdownItem