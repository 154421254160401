import React from 'react'
import Modal from 'react-bootstrap/Modal'


const StreamModal = ({show, handleCloseModal, program, currentUser}) => {

  document.addEventListener('DOMContentLoaded', function() {
    let videoElement = document.getElementById('myVideo')
    let pageUrl = window.location.href()
  
    videoElement.addEventListener('play', function() {  
      // Assuming you've already set up gtag and configured your GA4 property
      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', 'play', { // 'play' is the action being tracked.
          'event_category': 'Video',
          'event_label': pageUrl,
          'user_email': currentUser.userId, // This assumes user_email is setup as a custom dimension in GA4.
          'user_company': 'company'
        })
      }
    })
  })

  return (
    <Modal show={show} id='stream-modal' onHide={handleCloseModal} className='custom-modal-size'>
      <div className='close-modal-wrapper'>
        <button type='button' className='closeModal' aria-label='Close' onClick={handleCloseModal}>
          <span aria-hidden='true'>X</span>
        </button>
      </div>
      <Modal.Body>
        <video
          key={program.idNumber}
          autoPlay
          controls
          controlsList='nodownload'
          id='trailer-video'
          className='modal-video' >
          <source src={program.vU} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  )
}

export default StreamModal
