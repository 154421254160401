import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

const LoginModal = ({showLoginModal, handleCloseModal, userId}) => {
  const [otp, setOtp] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ mode, setMode ] = useState('')
  const auth = useAuth()

  const handleOTPChange = (event) => {
    setOtp(event.target.value)
  }

  const handleVerifyOTP = async (e) => {
    e.preventDefault()
    try {
      const res = await auth.verifyOTP(userId, otp)
      if(res.status === 200) {
        window.location.reload()  
      }
    } catch (error) {
      setShowToast(true)
      setMode('warning')
      setMessage('Incorrect One Time Passcode entered.')
      setOtp('')
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
      console.error(error)
    }
  }
  
  return (
    <Modal show={showLoginModal} id='access-modal' dialogClassName='large-modal' onHide={handleCloseModal}>
      <div className="close-modal-wrapper">
        <button type="button" className="closeModal" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <Form onSubmit={handleVerifyOTP}> 
          <p className='text-center'>A One Time Passcode emailed to {userId}</p>
          <input 
          type='text' 
          placeholder='One Time Passcode' 
          className='form-control-lg'
          onChange={handleOTPChange}
        />
        <button className='btn-auth' type='submit'>Login</button>
        </Form>
      </Modal.Body>
      <ToastContainer className='my-toasts' position='middle-center' aria-live='polite' aria-atomic='true'>
          <Toast bg={mode} show={showToast}>
            <Toast.Body>
              <p>{message}</p>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Modal>
  )
}
export default LoginModal
