import { useEffect, useState } from 'react'
import MySpinner from '../utilities/MySpinner'
import { Link } from 'react-router-dom'
import { getGenreUrl } from '../utilities/helpers'

const ProgramTombstone = ({ program }) => {
  const [urlSlug, setUrlSlug] = useState([])

  useEffect(() => {
    if (program && program.genre && program.genre.length > 0) {
      setUrlSlug(program.genre.map(genre => getGenreUrl(genre)))
    } else {
      // Reset or clear slugs if no genres are present
      setUrlSlug([])
    }
  }, [program])

  if (!program || !(urlSlug.length > 0)) return <MySpinner />

  // Generate JSON-LD schema object
  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "TVSeries",
    "@id": `#${program.idNumber}`,
    "name": program.title,
    "numberOfEpisodes": program.epCount.replace(/\//g, 'x'),
    "genre": program.genre,
    "datePublished": program.prodYears,
    "videoFormat": program.vQ[0]
  }

  return (
    <div className='program-details'>
      {/* JSON-LD Script */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLdSchema)}
      </script>
      <ul className='program-tombstone-metadata'>
        <li className='metadata-key'>Episodes & Length:</li>
        <li className='metadata-field'>{program.epCount.replace(/\//g, 'x')}</li>
        <li className='metadata-key'>Genre:</li>
        <li className='metadata-field'>
          {program.genre.map((genre, index, array) => (
            <Link to={`/genres/${urlSlug[index]}`} key={index}>
              {genre}{index < array.length - 1 ? ', ' : ''}
            </Link>
          ))}
        </li>
        <li className='metadata-key'>Production Year:</li>
        <li className='metadata-field'>{program.prodYears}</li>
        <li className='metadata-key'>Format:</li>
        <li className='metadata-field'>{program.vQ[0]}</li>
      </ul>
    </div>
  )
}
export default ProgramTombstone
