import React, { useLayoutEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import CarouselItem from 'react-bootstrap/CarouselItem'
import GenreHero from './GenreHero'
import MySpinner from '../utilities/MySpinner'

const GenreHeroCarousel = ({genre}) => {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if(!genre) return <MySpinner />

  return (
    <Carousel as='header' interval={5000} className='genre-carousel carousel-fade' controls={false} aria-label='Genre Carousel'>
      { genre.billboards.map(billboard => {
        return (
          <CarouselItem  className='genre-item'>
            <GenreHero billboard={billboard} genre={genre} />
          </CarouselItem>
        )
      })}     
    </Carousel>
  )
}
export default GenreHeroCarousel