import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
import { urlSlugTitle } from '../utilities/helpers'

const GenreCard = ({ genre }) => {
  const [ urlSlug, setUrlSlug ] = useState()

  useEffect(() => {
    setUrlSlug(urlSlugTitle(genre.name, genre.id))
  }, [genre])

  return (
    <Card className='genre-card mt-5'>
      <Link to={`/genres/${urlSlug}`} onClick={() => window.scroll(0, 0)}>
      <Card.Img variant='top' src={genre.imageURL} alt={`Feature image for ${genre.name}`} />
        <Card.Body>
          <Card.Title className='fw-bold'>{genre.name}</Card.Title>
        </Card.Body>
      </Link>
    </Card>
  )
}
export default GenreCard