import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const Director = ({directorObj, onDirectorChange, formFieldRefs}) => {
  const [director, setDirector] = useState({ directorFirst: '', directorLast: '' })

  useEffect(() => {
    if (directorObj) {
      setDirector(directorObj)
    }
  }, [directorObj])

  const handleChange = (e) => {
    const { id, value } = e.target
    const updatedDirector = { ...director, [id]: value }
    setDirector(updatedDirector)
    onDirectorChange(updatedDirector) // Notify parent component about the change
  }
  return (
    <>
      <Col sm={6} className='input-container'>
        <Form.Control
          size='lg'
          type='text'
          id='directorFirst'
          placeholder={`Director's First Name`}
          value={director.directorFirst}
          onChange={handleChange}
          maxLength='32'
          required  
        />    
      </Col>
      <Col sm={6} className='input-container'>
        <Form.Control
          size='lg'
          type='text'
          id='directorLast'
          placeholder={`Director's Last name`}
          value={director.directorLast}
          onChange={handleChange}
          maxLength='32'
          required  
        /> 
      </Col>
    </>
  )
}

export default Director
