import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const Producer = ({producerObj, onProducerChange}) => {
  const [producer, setProducer] = useState({ producerFirst: '', producerLast: '' })

  const handleChange = (e) => {
    const { id, value } = e.target
    const updatedProducer = { ...producer, [id]: value }
    setProducer(updatedProducer)
    onProducerChange(updatedProducer) // Notify parent component about the change
  }

  return (
    <>
      <Col sm={6} className='input-container'>
        <Form.Group controlId='producerFirst'>
          <Form.Control
            size='lg'
            type='text'
            id='producerFirst'
            placeholder={`Producer's First name`}
            value={producer.producerFirst}
            onChange={handleChange}
            maxLength='32'
          />
        </Form.Group> 
      </Col>
      <Col sm={6} className='input-container'>
        <Form.Group controlId='producerLast'>
          <Form.Control       
            size='lg'
            type='text'
            id='producerLast'
            placeholder={`Producer's Last name`}
            value={producer.producerLast}
            onChange={handleChange}
            maxLength='32'
          />
        </Form.Group>
      </Col>
    </>
  )
}

export default Producer
