import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Producers = () => {
  const description = `APT Worldwide helps producers monetize their content across media platforms globally.`
  const keywordsArr = 'producers, licensing, distribution, representation, worldwide'
  return (
    <>
      <Helmet>
        <title>Producers - APT Worldwide</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywordsArr} />
      </Helmet>
      <Container className='col-lg-8 offset-col-2'>
        <h1 className='text-center' style={{marginTop: '10rem'}}>PRODUCERS</h1>
        <div className='long-read-container'>
          <h4 className='text-center'>APT Worldwide helps producers monetize their content across media platforms globally.</h4>
          <p>APT Worldwide licenses content around the world for broadcast, streaming, on demand, educational, non-theatrical, footage, transportation and other media rights (excluding theatrical). We attend major international sales markets, including MIP, MIPCOM and MIPDOC, as well as specialty markets such as the APEX Content Market for airlines.</p>
          <p>Submit your professionally produced FACTUAL content for evaluation via the Submission Form below. We represent single programs, limited series, series, interstitials and collections in the following factual genres: art & culture, biography, business, current affairs, environment, general documentary, history, performance, science, social issues, health, nature, travel and lifestyle/how-to (such as cooking, home decorating, hobbies, etc.).</p>
          <p>We do not represent children's programs or any form of fictional content.</p>
          <p>Submissions are accepted throughout the year. However, there are specific submission dates to be considered for MIP-TV or MIPCOM:</p>
          <ul style={{ listStyle: 'none'}}>
            <li>July 15 for MIPCOM in October</li>
            <li>November 15 for MIP-TV or MIPDOC in February</li>
          </ul>
          <p>We only accept completed programs for our catalog, but will also review programs that are financed but not yet completed for future representation. If you are also interested in distribution to the US public tv stations through our parent company American Public Television, please indicate that on the Form.</p>
          <p className='prog-sub-link'><Link to='/program-submission' onClick={() => window.scroll(0,0)}>Submit your program to APT Worldwide</Link></p>
          <p>Questions?  Contact <a href='mailto:Kevin_McKenna@aptonline.org'>Kevin_McKenna@aptonline.org</a></p>
        </div>
      </Container>
    </>
  )
}

export default Producers
