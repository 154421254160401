const rearrangeString = (str) => {
  // Split the string into words
  const words = str.split(' ')

  // Check if the last word is 'A' or 'The'
  if (words.length > 1 && (words[words.length - 1] === 'A' || words[words.length - 1] === 'The')) {
      // Remove the last word
      const lastWord = words.pop()

      // Remove the comma from the new last word if it exists
      words[words.length - 1] = words[words.length - 1].replace(/,$/, '')

      // Reassemble the string with the last word at the front
      return `${lastWord} ${words.join(' ')}`
  }

  // Return the original string if no changes are needed
  return str
}

const reconstructTitle = (urlSlug) => {
  const title = urlSlug.replace(/\b(\w)/g, (match) => match.toUpperCase()).replace(/-/g, ' ')
  return title
}

const setBackground = () => {
  document.addEventListener("DOMContentLoaded", function() {
    const mainElement = document.querySelector('main')
    const longReadContainerExists = document.querySelector('.long-read-container') !== null
    const socialMediaRowExists = document.querySelector('.social-media-row') !== null
  
    if (longReadContainerExists || socialMediaRowExists) {
      mainElement.classList.add('subheader-background')
    }
  })  
}

const getGenreUrl = (name) => {
  const genreMapping = {
    'Art & Culture': 'art-&-culture-1',
    'Biography': 'biography-2',
    'Business': 'business-3',
    'Current Affairs': 'current-affairs-4',
    'General Documentaries': 'general-documentaries-5',
    'History': 'history-6',
    'IFE': 'IFE-7',
    'Lifestyle': 'lifestyle-8',
    'Military': 'military-9',
    'Performance': 'performance-10',
    'Science Health & Nature': 'science,-health-&-nature-11',
    'War Up Close': 'war-up-close-12',
  }
  return genreMapping[name];
}

const handleImageError = (e) => {
  e.target.src = 'https://images.aptww.org/IntlCatalog.nsf/vGenres/Art & Culture/$FILE/art_culture_1920x1080.jpg' // Default image path
}

const truncateStringAtFirstSpaceAfterLimit = (str, maxLength) => {
  if (str.length <= maxLength) return str // Return early if within limit
  // Find the index of the first space after maxLength
  const indexOfNextSpace = str.indexOf(' ', maxLength)
  // If there's a space after maxLength, truncate and add ellipsis
  if (indexOfNextSpace !== -1) {
    return `${str.substring(0, indexOfNextSpace)}...`
  }
  // If no space found, return the whole string up to maxLength and ellipsis
  return `${str.substring(0, maxLength)}...`
}

const urlSlugTitle = (title, id) => `${title.replace(/[\s/'"]/g, '-').toLowerCase()}-${id}`

const getIdFromSlug = (slug) => slug.split('-').pop()

  


// Usage
export { 
  getGenreUrl,
  rearrangeString, 
  setBackground,
  reconstructTitle,
  handleImageError,
  truncateStringAtFirstSpaceAfterLimit,
  urlSlugTitle,
  getIdFromSlug
}