import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ProgramCard from '../program/ProgramCard'
import { Container } from 'react-bootstrap'

const SearchResults = () => {
  const location = useLocation()
  const { results } = location.state || { results: []}
  const { searchTerm } = location.state || {}

  return (
    <div className='auth-container'>
      { results.length > 0 ? (
        <div className='text-center pb-3'>
          <h2>There {results.length === 1 ? 'is' : 'are'} {results.length} result{ results.length > 1 && 's'} for {searchTerm} </h2>
        </div>
        ) : (
        <h2 className='text-center'>There were no search results for "{searchTerm}"</h2>
      )}
      {console.log(results)}
      <Container className='search-container'>
        {results && results.filter(result => Number(result.idNumber) !== 70986).map(result => (
          <ProgramCard key={result.id} program={result} />
        ))}
      </Container>
    </div>
  )
}
export default SearchResults
