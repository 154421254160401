import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MySpinner from '../utilities/MySpinner'
import { FileEarmarkPdfFill } from 'react-bootstrap-icons'
import ProgramContext from '../../context/ProgramContext'
import { urlSlugTitle } from '../utilities/helpers'

const ProgramDescription = ({program}) => {
  const { programs } = useContext(ProgramContext)
  const [description, setDescription] = useState([])
  const [relatedLinks, setRelatedLinks] = useState([]) 
  const { related } = program
  
  useEffect(() => {
    const encodedText = program.longDesc
    const decodedText = decodeURIComponent(encodedText.replace(/\+/g, ' '))
    const cleanText = decodedText.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?b>/gi, '').replace(/<\/?i>/gi, '')
    const textArray = cleanText.split('\n')
    setDescription(textArray)
  },[program])
  
  useEffect(() => {
    if(related) {
      const links = related.map(link => {
        const relatedProgram = programs.find(p => p.idNumber === link.id)
        if (relatedProgram) {
          return {
            label: link.label,
            path: `/programs/${urlSlugTitle(link.label,link.id)}`,
            state: { relatedProgram }
          }
        }
        return null
      }).filter(Boolean)  // Filter out any null values in case there's no match
      setRelatedLinks(links)
    }
  }, [related, programs])
  
  if(!program) <MySpinner />

  return (
    <div className='program-description' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
      <div className='print-container' itemprop='abstract'>        
        {description.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
      { program.pA.length > 0 && 
        <div className='program-press mb-3' itemprop='review'>
          <h4>Press Links</h4>
          {program.pA.map(press => (
            <div className='row mb-1'>
              {/* NEED TO REMOVE ABSOLUTE VARIABLE AND USE DYNAMIC ENV VARIABLES FOR URL */}
              <a href={`https://images.aptww.org/${press.url}`} target='_blank' rel='noreferrer'><FileEarmarkPdfFill color='white' className='me-2' />{ press.pressTitle ? press.pressTitle : press.url}</a>
            </div>
          ))}
        </div>
      }
      {relatedLinks.length > 0 && 
        <div className='program-press'>
          <h4>Related Programs</h4>     
          {relatedLinks.map((link, index) => (
            <div className='row' key={index}>
              <Link to={link.path} state={link.state} onClick={() => window.scroll(0, 0)}>{link.label}</Link>
            </div>
          ))}
        </div>
      }
    </div>
  )
}
export default ProgramDescription
