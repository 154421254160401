import { useState, useEffect, useRef } from 'react'
import GenreDropdownItem from './GenreDropdownItem'

const GenreDropdown= ({ genres }) => {
  const alphaGenres = genres.filter(genre => Number(genre.id) > 0).sort((a, b) => a.name.localeCompare(b.name))
  const [show, setShow] = useState(false)
  const containerRef = useRef(null)

  const hideTooltip = () => {
    setShow(false)
  }

  const toggleTooltip = (e) => {
    e.stopPropagation()
    setShow(!show)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        hideTooltip()
      }
    }
    // Only add the event listener if the tooltip is shown
    if (show) {
      document.addEventListener('click', handleOutsideClick)
    }
    // Cleanup the event listener
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [show])

  return (
    <div className='genre-tooltip-container' ref={containerRef}>
      <button 
        className='btn-genre-dropdown genre-tooltip-trigger' 
        onClick={toggleTooltip} 
      >
        Genres
      </button>
      {show && (
        <div className='genre-tooltip'>
          {alphaGenres.map((genre) => (
            <GenreDropdownItem 
              genre={genre} 
              key={genre.id} 
              hideTooltip={hideTooltip}
            />
          ))}
        </div>
      )}
    </div>
  )
}
export default GenreDropdown
