import React, { useContext, useEffect, useState } from 'react'
import SearchInput from './components/catalog/SearchInput' // Adjust the import path as necessary
import ProgramContext from './context/ProgramContext'
import { ProgramContextProvider } from './context/ProgramContext'
import Container from 'react-bootstrap/Container'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import Navigation from './components/layout/Navigation'
import Footer from './components/layout/Footer'

const ErrorPage = () => {
  const [searchResults, setSearchResults] = useState([])
  const { programs, genres, mtKeys } = useContext(ProgramContext)

  const navigate = useNavigate()
  const location = useLocation()
  const oldUrl = location.pathname

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setSearchResults([])
      return
    }
    const trimmedTerm = searchTerm.trim()

    const results = programs.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(trimmedTerm.toLowerCase())
      )
    )
    navigate('/search-results', { state: { results, searchTerm } })
  }

  return (
    <ProgramContextProvider>
      <div className='root-layout'>
        <div className='error-nav'>
          <Navigation />
        </div>
        <div className='error-layout'>
          <Container className='long-read-container text-center' style={{color: '#f2f2f2'}}>
            <p>The page you are trying to access is not available</p>
            <div>
            <div className="error-search">
              <SearchInput onSearchSubmit={handleSearch}/>
            </div>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    </ProgramContextProvider>
  )
}

export default ErrorPage
