import React from 'react'
import Modal from 'react-bootstrap/Modal'

const TrailerModal = ({trailerShow, handleCloseModal, program}) => {
  return (
    <Modal 
      show={trailerShow} 
      id='trailer-modal' 
      dialogClassName='large-modal' 
      onHide={handleCloseModal}
      className='custom-modal-size'
    >
      <div className="close-modal-wrapper">
        <button type="button" className="closeModal" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <Modal.Body>
        <video
          key={program.idNumber}
          autoPlay
          controls
          id='trailer-video'
          className='modal-video' >
          <source src={program.sF} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  )
}
export default TrailerModal
