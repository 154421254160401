import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/AuthContext'
 
const DownloadModal = ({showDownloadModal, handleCloseModal, downloads, program}) => {
  const { currentUser } = useContext(AuthContext)

  const getFilename = (url) => {
    const lastIndex = url.lastIndexOf('/')
    const fileName = url.substring(lastIndex + 1)
    return fileName   
  }

  const handleDownloadButtonClick = (url) => {
    if (window.gtag && currentUser) {
      window.gtag('event', 'download', { 
        'event_category': 'Download Video',
        'event_label': url,
        'user_email': currentUser.userId, // This assumes user_email is setup as a custom dimension in GA4.
        'user_company': currentUser?.company ? currentUser.company : 'Company name needed'
      });
    }
  }
  
  return (
    <Modal show={showDownloadModal} onHide={handleCloseModal} className='download-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Available Downloads</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          To download a file, right-click its name and select:
        </p>
        <ul>
          <li>Chrome, Microsoft Edge, & Firefox: Save Link As...</li>
          <li>Internet Explorer: Save Target As...</li>
          <li>Safari: Download Linked File</li>
        </ul>
        {downloads.length < 1 ? (
          <p>There are no available downloads</p>
        ) : (
          downloads.filter(download => download.type === 'LF-WM').map((download, index) => (
            <div key={index}>
                <a href={download.vU} onContextMenu={(e)=> handleDownloadButtonClick(download.vU)} onClick={(e)=> handleDownloadButtonClick(download.vU)} aria-label={`Download ${program.title}`} target='_blank' rel='noreferrer' download>
                <p>{getFilename(download.vU)}</p>
              </a>
            </div>
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        Additional titles available <a href={`mailto:kevin_mckenna@aptonline.org?subject=Requesting more titles of ${program.title}`} aria-label={`Email for More Titles of ${program.title}`} target='_blank' rel='noreferrer'>upon request</a>
      </Modal.Footer>
    </Modal>
  )
}

export default DownloadModal