import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Container from 'react-bootstrap/Container'
import { useAuth } from '../../context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'

const OTPInput = () => {
  const [otp, setOtp] = useState('')
  const [userId, setUserId] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [ mode, setMode ] = useState('')
  const [ message, setMessage ] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [verified, setVerified] = useState(false)
  const location = useLocation()
  const { userId: userIdFromLocation } = location.state || { }
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    userIdFromLocation && setUserId(userIdFromLocation)
    if (userId) {
      const otpInputField = document.getElementById('otp');
      if (otpInputField) {
        otpInputField.focus();
      }
    }
  }, [userIdFromLocation, userId])

  const handleOTPChange = (event) => {
    setOtp(event.target.value)
  }
  const handleUserIdChange = (event) => {
    setUserId(event.target.value)
  }

  const handleVerifyOTP = async (e) => {
    e.preventDefault()
    try {
      const res = await auth.verifyOTP(userId, otp)
      if(res === undefined) {
        setShowToast(true)
        setMode('warning')
        setMessage('Incorrect One Time Passcode. Try again.')
        setOtp('')
        setTimeout(() => {
          setShowToast(false)
        }, 3000)
      } else {
        navigate('/')
      }
    } catch (error) {
      console.error(error)      
    }
  }

  return (
    <Container className='auth-container col-lg-6'>
      <Container className='mb-3'>
        <h4 className='text-center'>A One Time Passcode has been sent to {userId}</h4>
      </Container>
      <Form onSubmit={handleVerifyOTP}>   
          <input
            type='text'
            placeholder='Enter Email'
            className='form-control-lg'
            value={userId}
            hidden='true'
            onChange={handleUserIdChange}
          />
          <input
            id='otp'
            type='text'
            placeholder='Enter One Time Passcode'
            className='form-control-lg'
            value={otp}
            onChange={handleOTPChange}
          />
        <button className='btn-auth' type='submit'>
          Submit
        </button>
      </Form>
      <ToastContainer className='my-toasts' position='middle-center' aria-live='polite' aria-atomic='true'>
        <Toast bg={mode} show={showToast}>
          <Toast.Body>
            <p>{message}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  )
}
export default OTPInput
