import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const VideoDownloadRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // Extract the path
    const path = location.pathname
    // Assuming the ID is always after '/vVideoDownloadByID/' in the path
    const idPart = path.split('/vVideoDownloadByID/')[1]
    // Assuming the ID is the first part of the idPart, before any '?' if present
    const id = idPart ? idPart.substring(0, 5) : null

    if (id) {
      navigate(`/video-download/${id}`)
    } else {
      navigate('/no-id-to-navigate-to') // Fallback or error route if ID is missing
    }
  }, [navigate, location])

  return null // Renders nothing, only performs redirection
}

export default VideoDownloadRedirect
