import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

const RequestAccess = ({requestAccess, handleCloseModal, setCurrentUserId, showLogin, showContact, setEmail}) => {
  const [ userId, setUserId ] = useState('')
  const [ waiting, setWaiting ] = useState(false)
  const auth = useAuth()

  const handleUserIdChange = (e) => {
    setUserId(e.target.value)
  }

  const handleRefreshOTP= async(e) => {
    e.preventDefault()
    setEmail(userId)
    setWaiting(true)
    try {     
      const res = await auth.getUserApproval(userId)
      if(res.data === false) {
        handleCloseModal()
        setEmail(userId)
        setEmail(userId)
        showContact()
        setWaiting(false)
      } else {
        // Set a timeout to navigate after 5 seconds
        handleCloseModal()
        setCurrentUserId(userId)
        showLogin()
        setWaiting(false)
      }
      } catch (error) {
      console.error(error)       
    }   
  }
  
  return (
    <Modal show={requestAccess} id='access-modal' dialogClassName='large-modal' onHide={handleCloseModal}>
      <div className='close-modal-wrapper'>
        <button type='button' className='closeModal' aria-label='Close' onClick={handleCloseModal}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <Modal.Body>
        <Form onSubmit={handleRefreshOTP}> 
          <p className='text-center'>Please enter your email. If you have an account an One Time Passcode will be emailed to you momentarily. If you do not, a contact form will be provided.</p>
          <input 
          type='text' 
          placeholder='Email' 
          className='form-control-lg'
          value={userId} 
          onChange={handleUserIdChange}
        />
        <button className={`btn-auth ${waiting ? 'btn-gradient' : ''}`} type='submit' disabled={waiting}>Submit</button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default RequestAccess
