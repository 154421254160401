import React, { useContext, useState } from 'react'
import Hero from './Hero'
import ProgramContext from '../../context/ProgramContext'
import Carousel from 'react-bootstrap/Carousel'

const HeroCarousel = () => {   
  const { hero } = useContext(ProgramContext)
  const [index, setIndex] = useState(0)
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }
  
  return (
    <Carousel controls={false} activeIndex={index} interval={3000} as='header' className='hero-carousel carousel-fade' onSelect={handleSelect}>
      { hero.sort((a, b) => Number(a.hp) - Number(b.hp)).map(program => {
        return (
          <Carousel.Item key={program.idNumber}>
            <Hero program={program}/>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}
export default HeroCarousel
