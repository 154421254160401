import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { handleImageError, urlSlugTitle } from '../utilities/helpers'

const Hero = ({ program }) => {
  const [urlSlug, setUrlSlug] = useState('')
  const properTitle = program.featuredTitle
  const [ prefImage, setPrefImage ] = useState()
  
  useEffect(() => {
    setUrlSlug(urlSlugTitle(program.title, program.idNumber))
    const hpImage = program.billboard.find(img => img.hp || program.billboard[0]);
    setPrefImage(hpImage.imageLink)

  },[program])

  return (
    <div className='hero-container' role='article' aria-label={`Program information for ${program.title}`}>
      <Link 
        to={`/programs/${urlSlug}`} 
        state={{ program }}
        aria-label={`Learn more about ${program.title}`}
      >
        <div className='hero-img-wrapper' key={program.idNumber}>
          <img 
            src={prefImage} 
            alt={`Cover for ${program.title}`} 
            className='cover hero-img'
            onError={handleImageError}
          />
        </div>
        <div className={properTitle.length > 40 ? 'long-title nowrap' : 'program-title nowrap'}>
          {program.featuredTitle}
        </div>
        {/* <div className='mobile-title'>
          <h1 className='text-center' style={{margingTop: '1rem', marginBottom: '2rem'}}>{program.featuredTitle}</h1>
        </div> */}
      </Link>
    </div>
  )  
}
export default Hero
