import GenreCarousel from '../genres/GenreCarousel'
import HeroCarousel from '../layout/HeroCarousel'
import Blurb from './Blurb'
import { Helmet } from 'react-helmet'

const Home = () => {
  const description = `APT Worldwide licenses high-quality, non-fiction programming  including documentaries, science, current affairs, history and biographies across multiple distribution platforms, including terrestrial, cable, digital, satellite, streaming, home video, air lines and educational.`
  const keywordsArr = `APT Worldwide, Licensing, American, programming, non-fiction, documentary, history, science, technology, travel, cooking, health, programs, license`
  return (
    <>
      <Helmet>
        <title>APT Worldwide</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywordsArr} />
      </Helmet>
      <HeroCarousel />
      <Blurb />
      <GenreCarousel />
    </>
  )
}
export default Home