import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'

const About = () => {
  const description = `APT Worldwide licenses high-quality, non-fiction programming  including documentaries, science, current affairs, history and biographies across multiple distribution platforms, including terrestrial, cable, digital, satellite, streaming, home video, air lines and educational.`
  const keywordsArr = `APT Worldwide, Licensing, American, programming, non-fiction, documentary, history, science, technology, travel, cooking, health, programs, license`

  return (
    <>
      <Helmet>
        <title>About APT Worldwide</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywordsArr} />
      </Helmet>
      <Container className='col-lg-8 offset-col-2' itemscope itemtype='https://schema.org/NewsMediaOrganization' itemid='APT'>
        <h1 className='text-center' style={{marginTop: '10rem'}}>About <span itemprop='name'>APT Worldwide</span></h1>
        <div className='long-read-container' itemprop='description'>
          <p>APT Worldwide is a major supplier of high-quality, factual content to media companies around the world. Our diverse catalog features award-winning, highly produced history, science, nature, art & culture, biography, health, business and general documentary programs, as well as popular cooking, travel and lifestyle series.</p>
          <p>APT Worldwide works with media companies in every territory to license rights across all distribution platforms (excluding theatrical) including Broadcast, Streaming, VOD, Educational, Non-Theatrical, IFE, Footage and Transportation.</p>
          <br />
          <ul className='contact-list'>
            <li>Judy Barlow, Executive VP, International Sales -- judy_barlow « at » aptonline dot org</li>
            <li>Tom Davison, Director, Business Development -- tom_davison « at » aptonline dot org</li>
            <li>Kevin McKenna, Director, International Sales -- kevin_mckenna « at » aptonline dot org</li>
            <li>David Potashnik, Content Coordinator -- david_potashnik « at » aptonline dot org</li>
            <li>Questions?  Please contact any team member above.</li>
          </ul>
        </div>
      </Container>
    </>
  )
}
export default About

