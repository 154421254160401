import { useState, useContext } from 'react'
import Logo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import GenreDropdown from '../menus/GenreDropdown'
import Container from 'react-bootstrap/Container'
import { useAuth, AuthContext } from '../../context/AuthContext'
import ProgramContext from '../../context/ProgramContext'
import SearchInput from '../catalog/SearchInput'

const Navigation = () => {
  const [show, setShow] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState([])
  // eslint-disable-next-line no-unused-vars
  const { programs, genres, mtKeys } = useContext(ProgramContext)
  const { isAuthenticated, currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const auth = useAuth()
   
  const handleClick = async(e) => { 
    e.preventDefault()
    setShow(false)
    await auth.logout()
  }
  
  const handleClose = () => setShow(!show) // Toggle the show state

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setSearchResults([])
      return
    }
    const trimmedTerm = searchTerm.trim()

    const results = programs.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(trimmedTerm.toLowerCase())
      )
    )
    navigate('/search-results', { state: { results, searchTerm } })
  }

  return (
    <Navbar bg='transparent' expand={false} className='ww-navbar'>
      <Navbar.Toggle aria-controls='offcanvasNavbar' onClick={handleClose} className='ww-navbar-toggler' />
      <Container>
        <Navbar.Brand href='/'>
          <img src={Logo} alt='APT Worldwide' className='navbar-brand'/>
        </Navbar.Brand>
        <Nav.Item>
          <GenreDropdown genres={genres} />  
        </Nav.Item>
          <Nav.Item className='user-nav'>
            {!isAuthenticated && (
              <Nav.Link as={Link} style={{ color: '#f2f2f2' }} to='/new-otp'>
                <button className='btn-logout'>Login</button>
              </Nav.Link>
            )}
            {isAuthenticated && (
              <>
                <span className='user-email'>{ currentUser?.userId}</span>
                <Nav.Link onClick={handleClick}>
                  <button className='btn-logout'>Logout</button>
                </Nav.Link>
              </>
            )}
          </Nav.Item>
        <SearchInput onSearchSubmit={handleSearch}/>
      </Container>
        {/* Hamburger Menu */}
        <Navbar.Offcanvas id='offcanvasNavbar' show={show} onHide={handleClose} placement='end'>
          <Offcanvas.Header closeButton className='ww-offcanvas-header pt-5'>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Nav className='justify-content-end flex-grow-1 pe-3'>
            <Nav.Link as={Link} to={'/about'} onClick={handleClose}>About</Nav.Link>
            <Nav.Link as={Link} to={'/producers'} onClick={handleClose}>Producers</Nav.Link>
            <Nav.Link as={Link} to={'/contact-us'} onClick={handleClose}>Contact Us</Nav.Link>
            <div className='mobile-user'>
              <Nav.Item className='user-nav'>
              {!isAuthenticated && (
                <Nav.Link as={Link} to='/new-otp'>
                  Login
                </Nav.Link>
              )}
              {isAuthenticated && (
                <>
                  <span className='user-email'>{ currentUser?.userId }</span>
                  <Nav.Link onClick={handleClick}>
                    Logout
                  </Nav.Link>
                </>
              )}
            </Nav.Item>
            </div>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  )
}
export default Navigation