import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useFormContext } from '../../context/FormContext'

const ContactModal = ({handleCloseModal, showContactModal, email}) => {
  const formContext = useFormContext()
  const [formData, setFormData] = useState({
    name: '',
    org: '',
    email: email,
    message: ''
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await formContext.contactUs(formData)
      handleCloseModal()
    } catch (error) {
      setTimeout(() => {
        handleCloseModal()
      }, 3000)
      console.error(error)
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <>
    <Modal show={showContactModal} id='emailModal'>
      <div className='close-modal-wrapper'>
        <button type='button' className='closeModal' aria-label='Close' onClick={handleCloseModal}>
          <span aria-hidden='true'>X</span>
        </button>
      </div>     
        <Form onSubmit={handleSubmit}  style={{backgroundColor: '#f2f2f2'}}>
        <div className='col-10 offset-1 text-center'>
          <h5>Thank you for your interest in APT Worldwide</h5>
          <small className='text-center'>To request screening access or for more information about our services, please fill out the form below.</small>
        </div>
          <Modal.Body>
            <input className='form-control' type='text' name='name' placeholder='Name' onChange={handleChange}/>
            <input className='form-control' type='text' name='company' placeholder='Company' onChange={handleChange}/>
            <input className='form-control' type='email' value={email} name='email' placeholder='Email' onChange={handleChange}/>
            <textarea className='form-control' name='message' placeholder='Message' rows='5' onChange={handleChange}/>
            <input className='btn-email-submit' type='submit' value='Send' />
          </Modal.Body>
        </Form>
      </Modal>
      </>
  )
}

export default ContactModal
