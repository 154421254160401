import * as React from 'react'
import { createBrowserRouter } from 'react-router-dom' 
import VideoDownloadRedirect from './VideoDownloadRedirect'
import About from '../components/views/About'
import Home from '../components/views/Home'
import GenrePage from '../components/genres/GenrePage'
import ErrorPage from '../ErrorPage'
import RootLayout from './RootLayout'
import OTPInput from '../components/users/OTPInput'
import NewOTP from '../components/users/NewOTP'
import SearchResults from '../components/catalog/SearchResults'
import Producers from '../components/views/Producers'
import ContactUs from '../components/views/ContactUs'
import ProgramSubmission from '../components/forms/ProgramSubmission'
import QRCode from '../components/views/QRCode'
import ProgramPage from '../components/program/ProgramPage'
import VideoDownload from '../components/views/VideoDownload'
import OTPInputUserEntry from '../components/users/OTPInputUserEntry'

const Router = createBrowserRouter([ 
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/programs/:title',
        element: <ProgramPage />
      },
      {
        path: '/genres/:genre',
        element: <GenrePage />,
      },
      {
        path: '/about',
        element: <About />,
      },       
      {
        path: '/new-otp',
        element: <NewOTP />,
      },
      {
        path: '/otp-input',
        element: <OTPInput />
      },
      {
        path: '/otp-input-user',
        element: <OTPInputUserEntry />
      },
      {
        path: '/search-results',
        element: <SearchResults />
      },
      {
        path: '/producers',
        element: <Producers />
      },
      {
        path: '/program-submission',
        element: <ProgramSubmission />
      },
      {
        path: '/contact-us',
        element: <ContactUs />
      },
      {
        path: '/APTWW.nsf/vVideoDownloadByID/:id',
        element: <VideoDownloadRedirect />,
      },
      {
        path: `/video-download/:id`,
        element: <VideoDownload />
      },
      {
        path: '/qrcode',
        element: <QRCode />
      }   
    ]
  }
  
])
export default Router