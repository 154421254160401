import React, { createContext, useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(true) // Start with true to check token validity on load
  const [ errorMessage, setErrorMessage ] = useState()


  // Toast function to notify user
  const notify = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  // Function to check if the user's token is valid
  const validateToken = async (token) => {
    try {
      const res = await axios.post('https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/users/validate-JWT', { token })
      if (res.data.isValid) {
        setIsAuthenticated(true)
        setCurrentUser(res.data.user)
      } else {
        logout() // Logout user if token is invalid
        notify('Your session has expired. Please log in again.')
      }
    } catch (error) {
      console.error('Error validating token:', error)
      logout()
      notify('An error occurred. Please log in again.')
    } finally {
      setLoadingAuth(false)
    }
  }
  
  // Function to verify the provided OTP
  const verifyOTP = async (userId, otp) => {
    setLoadingAuth(true)
    try {
      const res = await axios.post('https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/users/verify-otp', { userId, otp })
      if (res.data.verified) {
        setIsAuthenticated(true)
        setLoadingAuth(false)
        setCurrentUser(res.data.user) // Or whatever user identifier you receive from the response
        localStorage.setItem('aptWorldwideUser', userId )
        const { token } = res.data
        localStorage.setItem('aptWorldwideJWT', JSON.stringify({ token }))
        return res
      }
      // Handle additional logic if needed
    } catch (error) {
      // Handle error, e.g., OTP is incorrect or expired
      setLoadingAuth(false)
      setErrorMessage('Error in verifying OTP:', error)
      setIsAuthenticated(false)
    }
  }

  // Function to log out the user
  const logout = () => {
    setCurrentUser(null)
    setIsAuthenticated(false)
    localStorage.removeItem('aptWorldwideJWT')
    localStorage.removeItem('aptWorldwideUser')
    localStorage.removeItem('hero')
    localStorage.removeItem('genres')
    localStorage.removeItem('qrSheets')
    localStorage.removeItem('MtKeys')
    localStorage.removeItem('programs')
    localStorage.removeItem('programs-content-length')
    localStorage.removeItem('genres-content-length')
    localStorage.removeItem('mtKeys-content-length')
  }

  // Function to attempt to load the user from localStorage and validate the token
  const loadUserFromLocalStorage = () => {
    const jwtString = localStorage.getItem('aptWorldwideJWT')
    if (jwtString) {
      const jwtObject = JSON.parse(jwtString)
      setCurrentUser(jwtObject.user)
      setIsAuthenticated(true) // Assume the user is authenticated initially
      validateToken(jwtObject.token) // Then validate the token asynchronously
    } else {
      // No token found, set isAuthenticated to false and stop loading
      setIsAuthenticated(false)
      setLoadingAuth(false)
    }
  }

  const getUserCompany = async(email) => {
    try {
      const res = await axios.post(`https://images.aptww.org/IntlCatalog.nsf/getBuyerByEmail?ReadForm&e=${email}`)
      return res.data      
    } catch (error) {
      console.error(error) 
      return null     
    }    
  }
  
  const getUserApproval = async(email) => {
    try {
      const data = {
        email: email // This sets the email in the request body
      }
      const res = await axios.post(`https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/users/verify-user`, data)
      return res
    } catch (error) {
      console.error(error) 
      return null           
    }    
  }
  
  useEffect(() => {
    loadUserFromLocalStorage()
  }, [])

  return (
    <AuthContext.Provider value={{ isAuthenticated, getUserApproval, currentUser, validateToken, verifyOTP, errorMessage, loadingAuth, logout, getUserCompany }}>
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  )
}
