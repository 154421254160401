import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from '../../context/FormContext'
import { useNavigate } from 'react-router-dom'
import Producer from './Producer'
import Director from './Director'
import Awards from './Awards'
import { Helmet } from 'react-helmet'

const ProgramSubmission = () => {
  const [ showToast, setShowToast ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ mode, setMode ] = useState('')
  const formContext = useFormContext()
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [awardObj, setAwardObj] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [directorObj, setDirectorObj] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [producerObj, setProducerObj] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [focusedField, setFocusedField] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [ submitted, setSubmitted ] = useState(false)
  const description = `APT Worldwide helps producers monetize their content across media platforms globally.`
 
  const [ formData, setFormData ] = useState({
    // CONTACT INFO
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    company: '',
    copyrightHolderName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    // // PROGRAM DETAILS
    programTitle: '',
    prodYear: '',
    trt: '',
    director: {directorFirst: '', directorLast: ''},
    producer: {producerFirst: '', producerLast: ''},
    synopsis: '',
    programMakerBio: '',
    videoLink: '', /* REQUIRED */
    award: [], /* EACH AWARD IS A COMBINED STRING OF FEST/ORG, AWARD NAME, AND YEAR */
    prevDistro: null,  /* BOOLEAN */
    additionalInfo: '',
    distroDesired: '',
    rights: ''
  })

  const {
    // eslint-disable-next-line
    firstName,
    // eslint-disable-next-line
    lastName,
    // eslint-disable-next-line
    jobTitle,
    // eslint-disable-next-line
    email,
    // eslint-disable-next-line
    company,
    // eslint-disable-next-line
    copyrightHolderName,
    // eslint-disable-next-line
    streetAddress,
    // eslint-disable-next-line
    city,
    // eslint-disable-next-line
    state,
    // eslint-disable-next-line
    zip,
    // eslint-disable-next-line
    country,
    // eslint-disable-next-line
    phone,
    // eslint-disable-next-line
    programTitle,
    // eslint-disable-next-line
    prodYear,
    // eslint-disable-next-line
    trt,
    // eslint-disable-next-line
    director,
    // eslint-disable-next-line
    producer,
    // eslint-disable-next-line
    synopsis,
    // eslint-disable-next-line
    programMakerBio,
    // eslint-disable-next-line
    videoLink,
    // eslint-disable-next-line 
    award, 
    // eslint-disable-next-line 
    prevDistro,
    // eslint-disable-next-line  
    additionalInfo,
    // eslint-disable-next-line
    distroDesired,
    // eslint-disable-next-line
    rights
  } = formData

  const formFieldRefs = useRef(
    Object.keys(formData).reduce((acc, key) => {
      acc[key] = React.createRef()
      return acc
    }, {})
  ).current
  
  const onMutate = (e) => {
    const { id, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }))
  } 
  
  const handleProducerChange = (updatedProducer) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      producer: {
        ...prevFormData.producer,
        ...updatedProducer
      },
    }))
  }
  
  const handleDirectorChange = (updatedDirector) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      director: {
        ...prevFormData.director,
        ...updatedDirector
      },
    }))
  }

  const handleAwardsChange = (newAwards) => {
    // Update formData state to include the latest awards
    setFormData(prevFormData => ({
      ...prevFormData,
      award: newAwards,
    }))
  }

  const onMutateRadio = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      prevDistro: value,
    }))
  }

  const isFieldValid = (key, value) => {
    if (['director', 'producer', 'award', 'rights', 'distroDesired', 'additionalInfo', 'prevDistro'].includes(key)) {
      return true
    }
    if (typeof value === 'string') {
      return value.trim() !== ''
    } else if (typeof value === 'boolean' || Array.isArray(value)) {
      return true
    }
    return false
  }
  
  const handleProgramSubmission = async(e) => {
    e.preventDefault()
    
    if (formData.prevDistro === null) {
      setMessage('Please indicate if your program has had previous distribution to submit your program for consideration.')
      setMode('danger')
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
      return // Prevent the form from submitting
    }
    if(formData.rights === '') {
      setMessage('Please provide a value for program rights to submit your program for consideration.')
      setMode('danger')
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
      return // Prevent the form from submitting
    }
    if(formData.distroDesired === '') {
      setMessage('Please let us know what kind of distribution you are seeking to submit your program for consideration.')
      setMode('danger')
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
      return // Prevent the form from submitting
    }
    let isValid = true
    let firstInvalidFieldName = null

    Object.entries(formData).forEach(([key, value]) => {
      if (!isFieldValid(key, value)) {
        isValid = false
        if (!firstInvalidFieldName) { // Set the first invalid field name if not already set
          firstInvalidFieldName = key
        }
      }
    })

    if (!isValid && firstInvalidFieldName) {
      // Access the ref of the first invalid field to get its placeholder
      const firstInvalidFieldRef = formFieldRefs[firstInvalidFieldName]
      const placeholderText = firstInvalidFieldRef.current?.getAttribute('placeholder') || 'the field'

      // Show an error message using the placeholder text
      setMessage(`Please enter a value for ${placeholderText}.`)
      setMode('danger')
      setShowToast(true)
      // Set focus to the first invalid field, if possible
      setTimeout(() => {
          setShowToast(false)
          firstInvalidFieldRef.current?.focus()
      }, 3000)
      return // Prevent form submission
    } 

    setSubmitted(true)
    setMessage('Submitting...')
    setMode('info')
    setShowToast(true)

    // Reset focused field before attempting submission
    setFocusedField('')
    // Ensure 'Submitting...' is displayed for at least 1 second
    setTimeout(async () => {
      try {     
        await formContext.submitProgram(formData)
        setMode('success')
        setMessage('Submission successful. You have been sent an email confirming your submission. The APT Worldwide team will be in touch.')
      } catch (error) {
        setMode('warning')
        setMessage('Submission unsuccessful. Please attempt again. If this is repeatedly happening reach out to info@aptww.org')
      } 
      // Wait for 8 seconds to show the submission result, then proceed
      setTimeout(() => {
        setShowToast(false)
        window.scroll(0,0) // Ensure this is before navigate for smooth UX
        navigate('/') // Adjust this as needed
      }, 5000) // Adjust time as needed
  
    }, 1000) // 1-second delay before executing submission logic
  }
  
  return (
    <>
      <Helmet>
        <title>APT Worldwide - Program Submission Portal</title>
        <meta name='description' content={description} />
        <meta name='keywords' content='boilerplate' />
      </Helmet>
      <Container className='program-submission-container'>
        <h1 className='text-center text-uppercase'>Program Submission</h1>
        <Form noValidate onSubmit={handleProgramSubmission}>
          <h2 style={{marginLeft: '2rem'}}>Your contact information</h2>
          <Row>
            <Col sm={6} className='input-container'>
                <Form.Control 
                  size='lg'
                  type='text'
                  id='firstName'
                  placeholder={`First Name`}
                  value={formData.firstName}
                  onChange={onMutate}
                  maxLength='32'
                  ref={formFieldRefs.firstName}
                  />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='lastName'
                placeholder={`Last Name`}
                value={formData.lastName}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='jobTitle'
                placeholder={`Job Title`}
                value={formData.jobTitle}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.jobTitle}
              />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='email'
                placeholder={`Email`}
                value={formData.email}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.email}
              />   
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='company'
                placeholder={`Company Name`}
                value={formData.company}
                onChange={onMutate}
                ref={formFieldRefs.company}
              />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='copyrightHolderName'
                placeholder={`Copyright Holder's Name`}
                value={formData.copyrightHolderName}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.copyrightHolderName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='streetAddress'
                placeholder={`Street Address`}
                value={formData.streetAddress}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.streetAddress}
              />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='city'
                placeholder={`City`}
                value={formData.city}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.city}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='state'
                placeholder={`State`}
                value={formData.state}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.state}
              />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='zip'
                placeholder={`Zip/ Postal Code`}
                value={formData.zip}
                onChange={onMutate}
                maxLength='32' 
                ref={formFieldRefs.zip}              
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='list'
                id='country'
                placeholder={`Country`}
                value={formData.country}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.country}
              />
            </Col>
            <Col sm={6} className='input-container'>
                <Form.Control
                  size='lg'
                  type='text'
                  id='phone'
                  placeholder={`Phone number`}
                  value={formData.phone}
                  onChange={onMutate}
                  maxLength='32'
                  ref={formFieldRefs.phone}
                />
            </Col>
          </Row>
          <hr />
          <h2 style={{marginLeft: '2rem'}}>Program Details</h2>
          <Row className='input-container'>
            <Col sm={12}>
              <Form.Control
                size='lg'
                type='text'
                id='programTitle'
                placeholder={`Program Title`}
                value={formData.programTitle}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.programTitle}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='input-container'>
              <Form.Control                
                size='lg'
                type='text'
                id='prodYear'
                placeholder={`Production Year`}
                value={formData.prodYear}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.prodYear}
              />
            </Col>
            <Col sm={6} className='input-container'>
              <Form.Control
                size='lg'
                type='text'
                id='trt'
                placeholder={`Total Running Time`}
                value={formData.trt}
                onChange={onMutate}
                maxLength='32'
                ref={formFieldRefs.trt}
              />
            </Col>
          </Row>
          <Row>
            <label>Director</label>
            <Director directorObj={{ directorFirst: formData.directorFirst, directorLast: formData.directorLast }} onDirectorChange={handleDirectorChange}/>    
          </Row>
          <Row>
            <label>Producer</label>   
            <Producer producerObj={{ producerFirst: formData.producerFirst, producerLast: formData.producerLast }} onProducerChange={handleProducerChange}/>
          </Row>
          <Row className='input-container' style={{margin: '1rem'}}>
            <Form.Control
              size='lg'
              as='textarea'
              id='synopsis'
              placeholder={`Synopsis`}
              value={formData.synopsis}
              onChange={onMutate}
              rows='5'
              ref={formFieldRefs.synopsis}
            />
          </Row>
          <Row className='input-container' style={{margin: '1rem'}}>
            <Form.Control
              size='lg'
              as='textarea'
              id='programMakerBio'
              placeholder={`Program Maker's Bio`}
              value={formData.programMakerBio}
              onChange={onMutate}
              rows='5'
              ref={formFieldRefs.programMakerBio}
              />
          </Row>
          <Row className='input-container' style={{margin: '1rem'}}>
            <Form.Control
              size='lg'
              type='text'
              id='videoLink'
              placeholder={`Video link`}
              value={formData.videoLink}
              onChange={onMutate}
              maxLength='32'
              ref={formFieldRefs.videoLink}
            />
            <small style={{marginLeft: '1rem'}}>Link for trailer or full program</small><br />
          </Row>
          <Row>

          <label>Awards</label>    
          <Awards awardObj={awardObj} onAwardsChange={handleAwardsChange} />  
          </Row>
          <Form.Group>
            <Form.Label>Has this program had previous distribution?</Form.Label>
            <ToggleButtonGroup className='distro-toggle-btns' type='radio' name='prevDistro' value={formData.prevDistro} onChange={onMutateRadio}>
              <ToggleButton id='tbg-radio-1' value={true} variant={formData.prevDistro ? 'success' : 'outline-success'}>
                Yes
              </ToggleButton>
              <ToggleButton id='tbg-radio-2' value={false} variant={!formData.prevDistro && formData.prevDistro !== null ? 'danger' : 'outline-danger'}>
                No
              </ToggleButton>
            </ToggleButtonGroup>
            <small>* Required *</small>
          </Form.Group>
          <Row>
            <Form.Control
              as='textarea'
              rows='5' 
              id='additionalInfo'
              placeholder={`Additional Information`}
              value={formData.additionalInfo}
              onChange={onMutate}  
              ref={formFieldRefs.additionalInfo}       
              />
          </Row>
          <Row className='mb-2'>
            <Form.Select 
              size='lg'
              id='distroDesired'
              value={formData.distroDesired}
              onChange={onMutate}
              maxLength='32'         
              >    
              <option value=''>Distribution desired</option> {/* Placeholder-like option */}
              <option value='International distribution.'>International distribution.</option>
              <option value='Distribution in the United States'>Distribution in the United States</option>
              <option value='Distribution both in the United States and internationally'>Distribution both in the United States and internationally</option>
            </Form.Select>
            <small style={{marginLeft: '1rem'}}>* Required *</small>
          </Row>
          <Row>
            <Form.Select 
              size='lg'
              id='rights'
              value={formData.rights}
              onChange={onMutate}
              maxLength='32'          
              >
              <option value=''>Program Rights</option> {/* Placeholder-like option */}
              <option value='I am the rights holder.'>I am the rights holder.</option>
              <option value='I represent the rights holder'>I represent the rights holder</option>
            </Form.Select> 
            <small className='mb-3' style={{marginLeft: '1rem'}}>* Required *</small>
          </Row>
          <button className='btn-program-submission' type='submit'>Submit your program</button>   
        </Form>
        <ToastContainer className='my-toasts' position='bottom-center' aria-live='polite' aria-atomic='true'>
            <Toast bg={mode} show={showToast}>
              <Toast.Body>
                <p>{message}</p>
              </Toast.Body>
            </Toast>
          </ToastContainer>
      </Container>
    </>
  )
}

export default ProgramSubmission
