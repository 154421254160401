import packageJson from '../../../package.json'
import { Instagram, Facebook, Twitter } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'

const Footer = () => {

  return (
    <footer itemscope itemtype='https://schema.org/NewsMediaOrganization' itemid='APT'>
      <div className='row hide-version'>
        Version: {packageJson.version}
      </div>
      <Container className='social-media-row'>
        <div className='link-item'>
          <Link to='/about' onClick={() => window.scroll(0, 0)}>About</Link>
        </div>
        <div className='link-item'>
          <Link to='/producers' onClick={() => window.scroll(0, 0)}>Producers</Link>
        </div>
        <div className='link-item'>
          <Link to='/contact-us' onClick={() => window.scroll(0, 0)}>Contact Us</Link>
        </div>
      </Container>
      <div className='contact-info row'>
        <p className='text-center' itemprop='address'>APT Worldwide | 10 Post Office Square, Suite 1200N | Boston, MA 02109 USA | P: <span itemprop='telephone'>+1 617-338-4455</span></p>
        <p className='text-center'>All Rights Reserved; Content © 2024 APT Worldwide</p>
      </div>
      <div className='bottom-right-social'>
      <div className='mt-2'>
          <Link to='https://instagram.com/aptworldwide/' className='social-media-icon' target='_blank'><Instagram color='#091A36' size='24'/></Link>
        </div>
        <div className='mt-2'>
          <Link to='http://www.facebook.com/aptworldwide' className='social-media-icon' target='_blank'><Facebook color='#091A36' size='24'/></Link>
        </div>
        <div className='mt-2'>
          <Link to='https://twitter.com/APT_WW' className='social-media-icon' target='_blank'><Twitter color='#091A36' size='24'/></Link>
        </div>
      </div>
    </footer>
  )
}
export default Footer
