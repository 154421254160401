import React, { useLayoutEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import CarouselItem from 'react-bootstrap/CarouselItem'
import MySpinner from '../utilities/MySpinner'
import ProgramHero from './ProgramHero'

const ProgramHeroCarousel = ({program}) => {
  const { billboard, imgSrc } = program
  const imageLinks = billboard.map(item => item.imageLink)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  if(!program) return <MySpinner />
  const carouselItems = imageLinks.length > 0 ? imageLinks : [imgSrc]

  return (
    <Carousel as='header' interval={3000} className='program-carousel carousel-fade' controls={false} aria-label='Genre Carousel'>
      { carouselItems.map(img => {
        return (
          <CarouselItem key={img} style={{backgroundColor: 'white'}} className='program-item' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
            <ProgramHero src={img} program={program} />
          </CarouselItem>
        )
      })}
    </Carousel>
  )
}
export default ProgramHeroCarousel