import React from 'react'
import Container from 'react-bootstrap/Container'

const Blurb = () => {
  return (
    <Container className='offset-xl-2 col-xl-8 blurb-container text-center' itemscope itemtype='https://schema.org/NewsMediaOrganization' itemid='APT'>
      <p itemprop='description'>APT Worldwide is a major supplier of high-quality, factual programming to media
        platforms around the world. With a catalog of award-winning documentaries,
        highly produced science, history, health and business titles, engaging current
        affairs programs, as well as popular cooking and travel series. APT Worldwide is
        a division of American Public Television, a leading distributor of national
        television programming to public television stations in the United States.</p>
    </Container>
  )
}
export default Blurb

