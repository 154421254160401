// src/components/AnalyticsSetup.js
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useAnalyticsEvent } from '../../hooks/useAnalyticsEvent'

const AnalyticsSetup = () => {
  const sendEvent = useAnalyticsEvent()
  const { currentUser } = useContext(AuthContext)
  const userId = currentUser && currentUser.userId ? currentUser.userId : 'anonymous'

  useEffect(() => {
    sendEvent('page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      user_email: userId, // Custom dimension
    })
  }, [userId, sendEvent])

  return null // This component doesn't render anything
}

export default AnalyticsSetup
