import { useEffect } from 'react'

/**
 * Custom hook to set the document title.
 * @param {string} title - The title to set for the document.
 */
const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title
  }, [title]) // This effect will re-run only if the title changes
}

export default useDocumentTitle
