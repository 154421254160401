import React, { useContext } from 'react'
import GenreCard from './GenreCard'
import ProgramContext from '../../context/ProgramContext'
import MySpinner from '../utilities/MySpinner'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css' 
import 'slick-carousel/slick/slick-theme.css'


const GenreCarousel = () => {
  const { genres, loadingGenres } = useContext(ProgramContext)
  const genresArr = Object.values(genres)

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0', // Adjust based on size of the images or container
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: (
      <div>
        <div className='next-slick-arrow'>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className='next-slick-arrow rotate-180'>
        </div>
      </div>
    ),
  }

  return (
    <div className='genre-carousel'>
      <h2 className='genres-heading'>Genres</h2>
      <Slider {...settings} className='genre-slider'>
        { genresArr.filter(genre => Number(genre.id) > 0 ).map(genre =>(
          <div key={genre.id}>
            <GenreCard genre={genre}/>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default GenreCarousel
