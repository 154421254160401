import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import MySpinner from '../utilities/MySpinner'
import GenreBrowse from './GenreBrowse'
import ProgramContext from '../../context/ProgramContext'
import GenreHeroCarousel from './GenreHeroCarousel'
import { Helmet } from 'react-helmet'
import { getIdFromSlug } from '../utilities/helpers'


const GenrePage = () => {
  const { genres } = useContext(ProgramContext)
  const params = useParams()
  const [genre, setGenre] = useState(null)
  const navigate = useNavigate()
  
  useEffect(() => {
    if(genres) {      
      const foundGenre = genres.find(genre => genre.id === getIdFromSlug(params.genre))
      if(foundGenre){
        setGenre(foundGenre)
      } else {
      const timer = setTimeout(() => {
        navigate('/genre-lookup-error')
      }, 3000) 
      // Clear the timeout if the component is unmounted or if the program is found before the timeout is reached
      return () => clearTimeout(timer)
    }
    }
  }, [genres, params, navigate])

  // Separate useEffect for setting document title to ensure genre has been set
  useEffect(() => {
    if (genre) {
      document.title = `${genre.name} - APT Worldwide`
    }
  }, [genre])

  if (!genre) {
    return <MySpinner />
  }

  return (
    <>
      <Helmet>
        <title>{genre.name} - APT Worldwide</title>
        <meta name='description' content={genre.description} />
        <meta name='keywords' content={genre.name} />
      </Helmet>
      <div className='genre-container'>
        <GenreHeroCarousel genre={genre}/>
        <GenreBrowse genre={genre} />
      </div>
    </>
  )
}
export default GenrePage