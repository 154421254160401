import React from 'react'
import { RouterProvider } from 'react-router-dom'
import Router from './router/router'
import { AuthProvider } from './context/AuthContext'
import { ProgramContextProvider } from './context/ProgramContext'
import { FormProvider } from './context/FormContext'
import AnalyticsSetup from './components/utilities/AnalyticsSetup'

const App = () => {

  return (
    <>
      <AuthProvider>
        <ProgramContextProvider>
          <FormProvider>
            <AnalyticsSetup />
            <RouterProvider router={Router} />
          </FormProvider>
        </ProgramContextProvider>
      </AuthProvider>
    </>
  )
}
export default App
