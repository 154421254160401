import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useFormContext } from '../../context/FormContext'
import { useLocation, useNavigate } from 'react-router-dom'

const ContactUs = () => {
  const formContext = useFormContext()
  const location = useLocation()
  const navigate = useNavigate()
  const newEmail = location.state?.userId || ''
  const [formData, setFormData] = useState({
    name: '',
    org: '',
    email: newEmail,
    message: ''
  })

  const resetForm = (e) => {
    e.preventDefault()
    window.location.reload()    
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await formContext.contactUs(formData)
      navigate('/')
  
    } catch (error) {
      console.error(error)
    }
  }
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
    
  return (
    <Container className='col-sm-8 offset-col-2' style={{marginTop: '10rem'}}>
      <Form onSubmit={handleSubmit}>
        <div className='mt-4 mb-4 text-center'>
          <h4>Thank you for your interest in APT Worldwide</h4>
          <small className='text-center'>To request screening access or for more information about our services, please fill out the form below.</small>
        </div>   
        <div className='d-flex'>
          <div className='col-3 text-center mb-2 mt-2'>
            <Form.Label htmlFor='name'>
              Name
            </Form.Label>
          </div>
          <div className='col-lg-9'>
            <Form.Control type='text' id='name' name='name' value={formData.name} onChange={handleChange}/>
          </div>          
        </div>
        <div className='d-flex'>
          <div className='col-3 text-center mb-2 mt-2'>
            <Form.Label htmlFor='email'>
              Email
            </Form.Label>
          </div>
          <div className='col-lg-9'>
            <Form.Control type='text' id='email' name='email' value={formData.email} onChange={handleChange}/>
          </div>          
        </div>
        <div className='d-flex'>
          <div className='col-3 text-center mb-2 mt-2'>
            <Form.Label htmlFor='org'>
              Organization
            </Form.Label>
          </div>
          <div className='col-lg-9'>
            <Form.Control type='text' id='org' name='org' value={formData.org} onChange={handleChange}/>
          </div>          
        </div>
        <Form.Group className="mb-3">
          <Form.Label style={{marginLeft: '1rem'}}>Message</Form.Label>
          <Form.Control as="textarea" rows={3} id='message' name='message' value={formData.message} onChange={handleChange} />
        </Form.Group>
        <div>
          <input className='btn-email-submit' type='submit' value='Send' />
          <input className='btn-email-reset mt-2' type='submit' value='Reset' onClick={resetForm}/>
        </div>       
      </Form>
    </Container>
  )
}

export default ContactUs
