import { useContext, useState, useEffect } from 'react'
import ProgramContext from '../../context/ProgramContext'
import ProgramCard from '../program/ProgramCard'
import MySpinner from '../utilities/MySpinner'
import Container from 'react-bootstrap/Container'
import { useParams } from 'react-router-dom'
import { getIdFromSlug } from '../utilities/helpers'

const GenreBrowse = () => {
  const params = useParams()
  const [ filteredPrograms, setFilteredPrograms ] = useState([])
  const [ genre, setGenre ] = useState(null)
  const { programs, genres } = useContext(ProgramContext)  

  useEffect(() => {
    setGenre(genres.find(genre => genre.id === getIdFromSlug(params.genre)))
  }, [genres, params])
  
  useEffect(() => {
    if(genre && programs){
      const filtered = programs.filter(program => program.genre.includes(genre.name.replace(',', '')));
      filtered.sort((a, b) => a.title.localeCompare(b.title));
      setFilteredPrograms(filtered);
    }
  }, [genre, programs])
  
  if(!genre) {
    return <MySpinner />
  }

  return (
    <>
      <div className='pt-3 mt-3' style={{textAlign: 'center'}}>
        <h1 className='genre-name fw-bold'>{genre.name}</h1>
      </div>      
      <Container className='search-container pt-3'>
        { filteredPrograms.map(program => {
          return (
            <ProgramCard program={program} />
          )
        })}
      </Container>
    </>  
  )
}
export default GenreBrowse