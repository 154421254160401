import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

const NewOTP = () => {
  const [ userId, setUserId ] = useState('')
  const [ waiting, setWaiting ] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()

  const handleUserIdChange = (e) => {
    setUserId(e.target.value)
  }

  const handleRefreshOTP= async(e) => {
    e.preventDefault()
    setWaiting(true)
    try {
      const res = await auth.getUserApproval(userId)
      if(res.data === false){
        navigate('/contact-us', { state: { userId }})
        setWaiting(false)
      } else {
        navigate('/otp-input', { state: { userId: userId } })
        setWaiting(false)
      }
    } catch (error) {
      console.error(error)
    }   
  }
  
  return (
    <Container className='auth-container col-lg-6'>
      <div className='col-10 offset-1'>
      <p className='text-center'>Please enter your email. If you have an account an One Time Passcode will be emailed to you. Otherwise, a contact form will be provided.</p>
      </div>
      <Form onSubmit={handleRefreshOTP}>
        <input 
          type='text' 
          placeholder='Email' 
          className='form-control-lg'
          value={userId} 
          data-testid='email-input'
          onChange={handleUserIdChange}
        />
        <button className={`btn-auth ${waiting ? 'btn-gradient' : ''}`} type='submit' data-testid='submit-button' disabled={waiting}>Submit</button>
      </Form>    
    </Container>
  )
}
export default NewOTP
