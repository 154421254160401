import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import ProgramContext from '../../context/ProgramContext'
import TrailerModal from '../modals/TrailerModal'
import StreamModal from '../modals/StreamModal'
import RequestAccess from '../modals/RequestAccess'
import LoginModal from '../modals/LoginModal'
import ContactModal from '../modals/ContactModal'
import DownloadModal from '../modals/DownloadModal'

const ProgramVideo = ({program}) => {
  const [show, setShow] = useState(false)
  const [ trailerShow, setTrailerShow ] = useState(false)
  const [ requestAccess, setRequestAccess] = useState(false)
  const [ showLoginModal, setShowLoginModal ] = useState(false)
  const [ showContactModal, setShowContactModal ] = useState(false)
  const [ showDownloadModal, setShowDownloadModal ] = useState(false)
  const [ currentUserId, setCurrentUserId ] = useState('')
  const [ email, setEmail ] = useState('')
  const { isAuthenticated, currentUser } = useContext(AuthContext)
  const { downloadsStatic } = useContext(ProgramContext)
  const [ programDownloads, setProgramDownloads ] = useState([])

  useEffect(() => {
    const fetchDownloads = async () => {
      try {
      const res = downloadsStatic.filter(download => Number(download.id) === Number(program.idNumber))  
      if (res) {
        setProgramDownloads(res)   
      } else {
        setProgramDownloads([])   
      }
      } catch (error) {
        console.error('Failed to fetch downloads:', error)
        setProgramDownloads([])   
      }
    }
    fetchDownloads()
   }, [program, downloadsStatic])
  
  const handleTrailerButtonClick = () => {
    const email = currentUser?.userId ? currentUser.userId : 'anonymous'
    const { sF } = program
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'trailer', { 
        'event_category': 'Player Video',
        'event_label': sF.split('/').pop(),
        'user_email': email, 
        'user_company': currentUser?.company ? currentUser.company : 'Company name needed'
      });
    }
  }

  const handleStreamButtonClick = () => {
    const email = currentUser.userId
    const { vU } = program
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'stream', { 
        'event_category': 'Player Video',
        'event_label': vU.split('/').pop(),
        'user_email': email, 
        'user_company': currentUser?.company ? currentUser.company : 'Company name needed'
      });
    }
  }

  const streamVideo = () => {
    handleStreamButtonClick()
    setShow(true)
  }

  const trailerVideo = () => {
    handleTrailerButtonClick()
    setTrailerShow(true)
  }

  const showDownload = () => {
    setShowDownloadModal(true)
  }
  
  const handleSetEmail = (newEmail) => {
    setEmail(newEmail)
  }
  
  const showLogin = () => {
    setShowLoginModal(true)
  }
  
  const showContact = () => {
    setShowContactModal(true)
  }
   
  const showAccess = () => {
    setRequestAccess(true)
  }
  

  const handleCloseModal = () => {
    setShow(false) // Close the main video modal
    setTrailerShow(false) // Close the trailer modal
    setRequestAccess(false)
    setShowLoginModal(false)
    setShowContactModal(false)
    setShowDownloadModal(false)
  }

  return (
    <div className='program-video-row' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
      {/* TERNARY STATEMENTS TO ELIMINATE THE BUTTONS IF VIDEOS ARE NOT AVAILABLE */}
      { program.vU && 
        <button 
          className='btn-viewing' 
          onClick={ isAuthenticated ? streamVideo : showAccess }
        >Stream</button>}
      <button className='btn-viewing' onClick={trailerVideo}>Trailer</button>
      <button 
        className='btn-viewing' 
        onClick={ isAuthenticated ? showDownload : showAccess }
        >Download</button>
      <TrailerModal 
        program={program}
        trailerShow={trailerShow} 
        handleCloseModal={handleCloseModal}
        itemprop='video'
      />
      <StreamModal 
        program={program}
        show={show} 
        handleCloseModal={handleCloseModal} 
        currentUser={currentUser}
        itemprop='video'
      />
      <RequestAccess 
        requestAccess={requestAccess} 
        handleCloseModal={handleCloseModal}
        setCurrentUserId={setCurrentUserId}
        showLogin={showLogin} 
        showContact={showContact}
        setEmail={handleSetEmail}
      />
      <LoginModal 
        showLoginModal={showLoginModal} 
        handleCloseModal={handleCloseModal}
        setCurrentUserId={currentUserId}
        userId={currentUserId} 
      />
      <ContactModal 
        showContactModal={showContactModal} 
        handleCloseModal={handleCloseModal}
        email={email}
      />
      <DownloadModal 
        showDownloadModal={showDownloadModal} 
        handleCloseModal={handleCloseModal}
        program={program}
        downloads={programDownloads}
        currentUser={currentUser}
        itemprop='video'
      />
    </div>
  )
}
export default ProgramVideo