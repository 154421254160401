import { useEffect, useState, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import MySpinner from '../utilities/MySpinner'
import ProgramHeroCarousel from './ProgramHeroCarousel'
import MobileTombstone from './MobileTombstone'
import ProgramMetadata from './ProgramMetadata'
import ProgramContext from '../../context/ProgramContext'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import ProgramVideo from './ProgramVideo'
import Container from 'react-bootstrap/Container'
import { reconstructTitle, getIdFromSlug } from '../utilities/helpers'
import { Helmet } from 'react-helmet'

const ProgramPage = () => {
  const { programs, loadingPrograms } = useContext(ProgramContext)
  const params = useParams()
  const location = useLocation()
  const { program: stateProgram, link, billboard, relatedProgram } = location.state || {}
  const navigate = useNavigate()
  const [program, setProgram] = useState()
  useDocumentTitle(`${reconstructTitle(params.title)} - APT Worldwide`)

  useEffect(() => {
    let timeoutId
    if(stateProgram) {
      setProgram(stateProgram)
    } else if (relatedProgram){
      setProgram(relatedProgram)
    } else if (link) {
      setProgram(programs.find(p => p.idNumber === String(link.id)))
    } else if (billboard) {
      setProgram(programs.find(p => p.idNumber === String(billboard.pid)))
    } else {
      const foundProgram = programs.find(p => p.idNumber === getIdFromSlug(params.title))
      if (foundProgram) {
        setProgram(foundProgram)       
      } else {
        timeoutId = setTimeout(() => {
          navigate('/no-program-id-match')               
        }, 3000)
      }      
    }
    return () => clearTimeout(timeoutId)
  }, [stateProgram, programs, link, billboard, navigate, relatedProgram, params ])

  if(!program || loadingPrograms) return <MySpinner />

  return (
    <>
      <Helmet>
        <title>{program.featuredTitle} - APT Worldwide</title>
        <meta name='description' content={program.shortDesc} />
        <meta name='keywords' content={program.mtKeys.join(', ')} />
      </Helmet>
      <div className='program-container' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
        <ProgramHeroCarousel program={program} />
        <div className={ program.featuredTitle.length > 40 ? 'long-title mt-4' : 'program-title mt-4'} itemprop='name'>
          {program.featuredTitle}
        </div>
        <div className='mobile-title'>
          <h1 className='text-center' itemprop='name'>{program.featuredTitle}</h1>
        </div>
        <ProgramVideo program={program} />
        <Container>
          <MobileTombstone program={program} />
          <ProgramMetadata program={program} />
        </Container>
      </div> 
    </>
  )
}
export default ProgramPage
