import React, { createContext, useContext } from 'react'
import axios from 'axios'

export const FormContext = createContext()

export const useFormContext = () => useContext(FormContext)

export const FormProvider = ({ children }) => {

  // Function to generate a new Program Submission
  const submitProgram = async (submission) => {
    try {
      const res = await axios.post('https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/forms/program-submission', { ...submission })
      return res
    } catch (error) {
      console.error('Error making the submission:', error)
    }
  }

  const contactUs = async(submission) => {
    try {
      await axios.post('https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/forms/contact-us', { ...submission})   
    } catch (error) {
    console.error(error)
    }
  }

  return (
    <FormContext.Provider value={{ submitProgram, contactUs }}>
      {children}
    </FormContext.Provider>
  )
}
