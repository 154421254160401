import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const Awards = ({ awardObj, onAwardsChange }) => {
  // Initialize state with the existing award objects
  const [awardsField, setAwardsField] = useState(awardObj.length > 0 ? awardObj : [{ awardOrg: '', awardName: '', awardYear: '' }])

  // Rest of your component logic...
  const onMutate = (index, field, value) => {
    const updatedAwards = [...awardsField]
    updatedAwards[index][field] = value
    setAwardsField(updatedAwards) // Update the state with the modified array
    onAwardsChange(updatedAwards) // Pass the updated awards array to the parent
  }

  const addNewAward = () => {
    setAwardsField([...awardsField, { awardOrg: '', awardName: '', awardYear: '' }]) // Append to the existing array
  }

  const removeAward = (index) => {
    const updatedAwards = [...awardsField]
    updatedAwards.splice(index, 1)
    setAwardsField(updatedAwards) // Update the state with the modified array
    onAwardsChange(updatedAwards) // Pass the updated awards array to the parent
  }

  return awardsField.map((award, index) => (
    <div key={index}>
        <Form.Group>
          <Form.Control
            size='lg'
            type='text'
            placeholder='Festival/ Organization'
            value={award.awardOrg}
            onChange={(e) => onMutate(index, 'awardOrg', e.target.value)}
            maxLength='32'
            required
          />
          <Form.Control
            size='lg'
            type='text'
            placeholder='Award name'
            value={award.awardName}
            onChange={(e) => onMutate(index, 'awardName', e.target.value)}
            maxLength='32'
            required
          />
          <Form.Control
            size='lg'
            type='text'
            placeholder='Year'
            value={award.awardYear}
            onChange={(e) => onMutate(index, 'awardYear', e.target.value)}
            maxLength='32'
            required
            />
          <div key={index} className="button-row">
              {index > 0 && (
                <Button variant="danger" className="btn-add remove-btn" onClick={() => removeAward(index)}>
                  Remove
                </Button>
              )}
              {index === awardsField.length - 1 && (
                <Button variant="success" className="btn-add add-btn" onClick={addNewAward}>
                  Add Another Award
                </Button>
              )}
        </div>
      </Form.Group>
    </div>
    ))
}

export default Awards
